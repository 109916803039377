@import "src/styles/config";

.wrapper {
    flex: 1;
    display: flex;
    overflow: hidden;
    background: $clrLightGray;
}

.left {
    flex: 0 0 440px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 10px 0 $clrBorder;
    background: $clrWhite;
    position: relative;

    hr {
        /* have to add important because of #react-root hr {} rule. */
        margin: 28px 0 26px !important;
    }

    .leftContent {
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 25px;
        //max-height: calc(100vh - 153px - 25px - 25px);
        max-height: calc(100vh - 203px);

        &.noActions {
            //max-height: calc(100vh - 72px - 25px - 25px);
            max-height: calc(100vh - 122px);
        }
    }

    .leftActions {
        display: flex;
        justify-content: flex-end;
        padding: 24px 30px;
        background-color: $clrGray1;
        box-shadow: 0 2px 10px 0 $clrBorder;

        button:first-child {
            margin-left: 0;
        }
    }
}

.content {
    flex: 1;
    padding: 40px;
    overflow-y: auto;
    max-height: calc(100vh - 72px);
    background: $clrLightGray;
    position: relative;
}
